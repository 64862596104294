import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Button } from 'app/components/Common/Button'
import { Props as MenuItemProps } from 'app/components/Common/Header/MenuItem'
import { Booking, Whatsapp } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Props as StickerProps, Sticker } from 'app/components/Sticker'
import { HeaderContext } from 'app/containers/Header'
import { theme } from 'app/theme'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useVocabularyData } from 'app/utils/vocabulary'
import disableScroll from 'disable-scroll'
import { rgba } from 'emotion-rgba'
import { Link } from 'gatsby'
import isbot from 'isbot'
import React, { memo, useContext, useEffect, useState } from 'react'

import { Props as LanguageItemProps } from './LanguageItem'
import { Languages } from './Languages'
import { ModalClose } from './ModalClose'
import { Navigation } from './Navigation'
import { Syncrobox } from './Syncrobox'

export interface Props {
  languagesList: LanguageItemProps[]
  menuItems: MenuItemProps[]
  pageID?: string
  stickerProps?: StickerProps
}

export const Header = memo(function Header({
  languagesList,
  menuItems,
  pageID,
  stickerProps,
}: Props) {
  const {
    IBEURL,
    languageCode,
    languagePrefix,
    logo,
    logoNegative,
    quotationPageURL,
    siteName,
    whatsappNumber,
    whatsappURL,
    variant,
  } = useContext(HeaderContext) as any

  const [scroll, setScroll] = useState(false)
  const [modalStatus, setModalStatus] = useState(false)

  useEffect(() => {
    const onScroll = () => {
      setScroll(window.scrollY > window.innerHeight)
    }

    disableScroll.off()

    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  const quotationPageSlug = quotationPageURL?.find(
    (t: any) => t?.languages_code?.code === languageCode,
  )?.slug

  const quotationRealPageURL = languagePrefix
    ? `/${languagePrefix}/${quotationPageSlug}`
    : `/${quotationPageSlug}`

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        {stickerProps ? <Sticker {...stickerProps} /> : null}

        <Head
          className={scroll || variant === 'compact' ? 'fixed' : ''}
          dial={4}
          row
          variant={variant}
        >
          {logo ? (
            <Link
              className="logo"
              to={languagePrefix ? `/${languagePrefix}` : '/'}
            >
              {logoNegative ? (
                <Media lessThan="desktopSmall">
                  <Logo
                    alt={siteName}
                    aria-label={siteName}
                    src={logoNegative}
                    width="67"
                    height="50"
                  />
                </Media>
              ) : null}
              {logo ? (
                <Media greaterThanOrEqual="desktopSmall">
                  <Logo
                    alt={siteName}
                    aria-label={siteName}
                    src={scroll || variant === 'compact' ? logoNegative : logo}
                    width="108"
                    height="80"
                  />
                </Media>
              ) : null}
            </Link>
          ) : null}

          {whatsappNumber ? (
            <Anchor
              className="header-whatsapp"
              aria-label="WhatsApp"
              href={whatsappURL}
            >
              <Whatsapp />
              {whatsappNumber}
            </Anchor>
          ) : null}

          <Languages
            className={
              scroll || variant === 'compact' || variant === 'simple'
                ? 'sticky'
                : undefined
            }
            languagesList={languagesList}
            pageID={pageID}
          />

          {quotationPageURL ? (
            <QuotationButton
              className="header-quotation"
              label={useVocabularyData('quotation', languageCode)}
              URL={quotationRealPageURL}
              variant="invert"
            />
          ) : null}

          {(IBEURL && scroll) || variant === 'compact' ? (
            <Button
              className="header-book"
              label={useVocabularyData('book', languageCode)}
              URL={`${IBEURL}&lang=${languagePrefix || 'it'}`}
              rel="noopener"
              target="_blank"
            />
          ) : null}

          <Hamburger
            className="hamburger-menu"
            dial={4}
            onClick={() => {
              if (!modalStatus) {
                setModalStatus(true)
              } else {
                setModalStatus(false)
              }
            }}
            row
          >
            <HambugerLabel>
              {useVocabularyData('menu', languageCode)}
            </HambugerLabel>
            <HamburgerLines className="hamburger-lines">
              <HamburgerLine />
              <HamburgerLine />
            </HamburgerLines>
          </Hamburger>

          <Media lessThan="desktopSmall">
            {IBEURL ? (
              <IBEButton
                href={`${IBEURL}&lang=${languagePrefix || 'it'}`}
                rel="noopener"
                target="_blank"
              >
                <Booking />
                {useVocabularyData('book', languageCode)}
              </IBEButton>
            ) : null}
          </Media>
        </Head>

        <Modal className={modalStatus ? 'open' : ''}>
          <ModalClose
            languageCode={languageCode}
            onClick={() => {
              setModalStatus(false)
            }}
          />
          <Navigation menuItems={menuItems} modalStatus={modalStatus} />
        </Modal>

        {variant === 'default' &&
        typeof window !== 'undefined' &&
        !isbot(navigator.userAgent) ? (
          <>
            <Media greaterThanOrEqual="desktopSmall">
              <Syncrobox
                languagePrefix={languagePrefix || 'it'}
              />
            </Media>

            <ButtonScroll
              dial={5}
              onClick={() =>
                window.scroll({
                  top: window.innerHeight + 1,
                  left: 0,
                  behavior: 'smooth',
                })
              }
            >
              Scroll
            </ButtonScroll>
          </>
        ) : null}
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`

const HeaderFixedStyle = css`
  background: ${theme.colors.variants.neutralLight4};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  .logo {
    img {
      width: auto;
      height: 4rem;
    }
  }
  .header-whatsapp {
    color: ${theme.colors.variants.neutralDark2};
    &:after {
      background: ${theme.colors.variants.neutralDark2};
    }
    svg {
      fill: ${theme.colors.variants.neutralDark2};
    }
  }
  .header-quotation {
    border-color: ${theme.colors.variants.neutralDark2};
  }
  .header-book {
    margin-left: 0.75rem;
  }
  .hamburger-menu {
    span {
      color: ${theme.colors.variants.neutralDark2};
    }
    .hamburger-lines {
      div {
        background: ${theme.colors.variants.neutralDark2};
      }
    }
  }
`

const Head = styled(FlexBox)<HeadProps>`
  width: 100%;
  padding: 1rem 3rem;
  position: relative;
  &.fixed {
    height: 6rem;
    animation: fadeInTop 0.3s;

    ${HeaderFixedStyle}
  }

  .logo {
    margin-right: auto;
  }

  ${({ variant }) => {
    switch (variant) {
      case 'compact':
        return css`
          animation: none !important;
        `
    }
  }}

  @media (max-width: 1199px) {
    height: 4.375rem !important;
    animation: none !important;
    padding-right: 0;
    padding-left: 1.875rem;

    ${HeaderFixedStyle}

    .logo {
      img {
        height: 3.125rem !important;
      }
    }
    .header-book,
    .header-whatsapp,
    .header-quotation {
      display: none;
    }
  }

  @media (max-width: 767px) {
    padding-left: 1.25rem;
  }

  @keyframes fadeInTop {
    0% {
      top: -5rem;
    }
    100% {
      top: 0;
    }
  }
`

const Logo = styled.img`
  width: auto;
  height: 5rem;
`

const Anchor = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.1875rem;
  margin-right: 2.25rem;
  &:hover {
    svg {
      transform: scaleX(-1);
    }
  }
  &:after {
    content: '';
    width: 0.125rem;
    height: 1.5rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    opacity: 0.2;
    margin-left: 1.4375rem;
  }

  svg {
    width: auto;
    height: 1.25rem;
    fill: ${({ theme }) => theme.colors.variants.neutralLight4};
    margin-right: 0.75rem;
    transition: 0.2s ease-in-out;
  }
`

const QuotationButton = styled(Button)`
  border: 0.0625rem solid transparent;
`

const Hamburger = styled(FlexBox)`
  cursor: pointer;
  margin-left: 2.25rem;
  position: relative;
  transition: 0.3s ease-in-out;
  &:hover {
    .hamburger-lines {
      height: 0.875rem;
    }
  }

  @media (max-width: 767px) {
    margin-left: 1.875rem;
  }
`

const HambugerLabel = styled.span`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.1875rem;
  margin-right: 1rem;

  @media (max-width: 1199px) {
    display: none;
  }
`

const HamburgerLines = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 1.5rem;
  height: 0.5rem;
  transform: translateY(0.0625rem);
  transition: 0.3s ease-in-out;
`

const HamburgerLine = styled.div`
  width: 100%;
  height: 0.125rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
`

const IBEButton = styled.a`
  display: block;
  width: 4.375rem;
  height: 4.375rem;
  background: ${({ theme }) => theme.colors.variants.primaryLight};
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.5625rem;
  font-weight: 600;
  line-height: 0.875rem;
  margin-left: 1.875rem;
  padding-top: 0.875rem;
  text-align: center;
  text-transform: uppercase;

  svg {
    display: block;
    width: auto;
    height: 1.625rem;
    fill: ${({ theme }) => theme.colors.variants.neutralDark2};
    margin: 0 auto 0.25rem;
  }
`

const ButtonScroll = styled(FlexBox)`
  height: 4rem;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.1875rem;
  padding-right: 1.75rem;
  position: absolute;
  top: calc(100vh - 5.5rem);
  right: 3rem;
  z-index: 5;
  &:before,
  &:after {
    content: '';
    width: 0.125rem;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }
  &:before {
    animation: scrolldown 2s cubic-bezier(0.76, 0, 0.3, 1) forwards infinite;
    background: linear-gradient(
      to bottom,
      ${rgba(theme.colors.variants.neutralLight4, 1)} 50%,
      ${rgba(theme.colors.variants.neutralLight4, 0)} 50%
    );
    background-position: 0 -4rem;
    background-size: 100% 200%;
  }
  &:after {
    background: ${({ theme }) => theme.colors.variants.neutralLight4};
    opacity: 0.2;
  }

  @media (max-width: 1349px) {
    display: none;
  }

  @keyframes scrolldown {
    0% {
      background-position: 0 -4rem;
    }
    35% {
      background-position: 0 0;
    }
    100% {
      background-position: 0 4rem;
    }
  }
`

const Modal = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: -1;
  transition: 0.2s ease-out;
  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 1000;
  }
`

interface HeadProps {
  variant: Variant
}

export type Variant = 'default' | 'compact' | 'noibe'
